import { Button, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, FormControl, FormLabel, FormErrorMessage, Icon, Grid, Stack, HStack, VStack, Divider, Select as Select2, useColorModeValue, useMultiStyleConfig, Heading } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import { getAuth, postAuth } from "../../../Utils/APIHelpers";



type userAcess = {
    firstName: String;
    lastName: String;
}

const map: Record<string, number[]> = {
    "Doubles": [0, 1, 2, 3],
    "Singles": [0, 1]
};

const score_values = [
    {value: 0, label: "0"},
    {value: 1, label: "1"},
    {value: 2, label: "2"},
    {value: 3, label: "3"},
    {value: 4, label: "4"},
    {value: 5, label: "5"},
    {value: 6, label: "6"},
    {value: 7, label: "7"}
]

function normalSelect(gameNumber: number,
    handleScoreChange: (index: number, value: number) => void
) { 
    return (<Select 
        chakraStyles={{
            dropdownIndicator: (provided) => ({
            ...provided,
            p: 0,
            w: "100%",
            bgColor: 'transparent'
            }),
            control: (provided, state) => ({
            ...provided,
            borderBottomLeftRadius: state.menuIsOpen ? 0 : "md",
            borderBottomRightRadius: state.menuIsOpen ? 0 : "md",
            transitionDuration: 0
            }),
            group: (provided) => ({
            ...provided,
            borderBottomWidth: "1px",
            _last: {
                borderBottomWidth: 0
            }
            }),
            groupHeading: (provided) => ({
            ...provided,
            px: "0.8rem",
            textTransform: "uppercase"
            }),
            menu: (provided) => ({
            ...provided,
            my: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderWidth: "1px",
            borderBottomRadius: "md"
            }),
            menuList: (provided) => ({
            ...provided,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderWidth: 0
            }),
            container: (provided) => ({
                ...provided,
                width: '22%'
            }),
            placeholder: (provided) => ({
                ...provided,
                fontSize: '12'
            })
        }}
        placeholder={"Set Score"} 
        options={score_values}
        onChange={(e) =>handleScoreChange(gameNumber, e?.value || 0)}/>)
}

function userSelectBox(users: userAcess[],
    index: number,
    handlePlayerSelect: (index: number, value: string) => void
) {
    return (
        <Select 
            chakraStyles={{
                container: (provided) => ({
                    ...provided,
                    width: '100%'
                }),
                dropdownIndicator: (provided) => ({
                    ...provided,
                    p: 0,
                    w: "100%",
                    bgColor: 'transparent'
                  })
            }}
            placeholder={`Player ${index + 1}`}
            onChange={(e) => handlePlayerSelect(index, e?.value || "")}
            key={index} 
            options={users.map((user) => ({value: user.firstName + " " + user.lastName, label: user.firstName + " " + user.lastName}))}/>
    )
}

type ReportScoresProps = {
    handleSetPlayers: (players: string[]) => void
    handleSetScores: (scores: number[]) => void
}

const ReportScores: React.FC<ReportScoresProps> = ({handleSetPlayers, handleSetScores}) => {

    const [matchType, setMatchType] = useState('');
    const [players, setPlayers] = useState<string[]>([]);
    const [score, setScore] = useState<number[]>([]);
    const [winners, setWinners] = useState<string[]>([]);

    const handleReportScores = () => {
        // Handle submission of scores
        postAuth("/users/elo/", {
            'user': players[0],
            'user2': players[1],
            'winner': winners,
            'scores': score
        })

        // Reset form state
        setMatchType('');
        setPlayers([]);
        setScore([]);
        setWinners([]);
    };

    const [users, setUsers] = useState<userAcess[]>([])

    async function fetchUsers() {
        try {
            const response = await getAuth(`/usersAccess/`);
            const json = await response.json();
            setUsers(json as userAcess[])
        } catch (error) {
            console.error('Error fetching routine details:', error);
        }
    }

    useEffect(() => {
        handleSetPlayers(players)
        handleSetScores(score)
    }, [score, players])

    useEffect(() => {
        fetchUsers()
    }, []); // Fetch routine details when RoutineID changes

    const handlePlayerSelect = (index: number, value: string) => {
        const newPlayers = [...players];
        newPlayers[index] = value;
        setPlayers(newPlayers);

        // Populate winner with selected players
        setWinners(newPlayers);
    };

    const handleScoreChange = (index: number, value: number) => {
        const newScores = [...score];
        newScores[index] = value;
        setScore(newScores);
    };

    return (
    <Flex width={'inherit'}>
        <Stack spacing={2} width={'inherit'}>
            <Select onChange={(e) => setMatchType(e?.label || "")}
                    placeholder={"Match Type"}
                    options={[
                        {value: "Singles", label: "Singles"},
                        {value: "Doubles", label: "Doubles"},
                    ]}
                    chakraStyles={{
                        container: (provided) => ({
                            ...provided,
                            width: '100%'
                        })
                    }}/>
            
            {matchType !== '' &&
            <VStack spacing={4} divider={<Divider></Divider>} mt={'8'}>
                <HStack spacing={9} width={'inherit'}>
                    <VStack width={'35vh'} spacing={4}>
                        {map[matchType].slice(0,map[matchType].length/2).map((num) => (userSelectBox(users, num, handlePlayerSelect)))}
                    </VStack>
                    {normalSelect(0, handleScoreChange)}
                    {normalSelect(1, handleScoreChange)}
                    {normalSelect(2, handleScoreChange)}
                </HStack>
                <HStack spacing={9} width={'inherit'}>
                    <VStack width={'35vh'} spacing={4}>
                        {map[matchType].slice(map[matchType].length/2).map((num) => (userSelectBox(users, num, handlePlayerSelect)))}
                    </VStack>
                    {normalSelect(3, handleScoreChange)}
                    {normalSelect(4, handleScoreChange)}
                    {normalSelect(5, handleScoreChange)}
                </HStack>
            </VStack>}
        </Stack>
    </Flex>)
}

export default ReportScores;