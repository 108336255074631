// LoginPage.tsx

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../Utils/APIHelpers';
import { isAuth } from '../../Utils/IsAuth';
import { Box, Button, Card, Checkbox, Text, CheckboxGroup, Flex, FormControl, HStack, Heading, Input, InputGroup, InputLeftElement, Link, Spacer, Stack, VStack } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import tennisImage from '../../Assets/tennis.jpg'
import { BsFileLockFill, BsPersonFill } from "react-icons/bs";
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import LoginContainer from './LoginContainer';

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [formError, setFormError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth()) {
            navigate('/dashboard');
        } else {
          const savedEmail = localStorage.getItem('rememberMeEmail');
          if (savedEmail) {
            setEmail(savedEmail);
            setRememberMe(true);
          }
        }
    }, [navigate]);

    const validateForm = () => {
        let isValid = true;
        setEmailError('');
        setPasswordError('');

        if (!email) {
            setEmailError('Email is required.');
            isValid = false;
        }

        if (!password) {
            setPasswordError('Password is required.');
            isValid = false;
        }

        return isValid;
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setFormError('');

        if (!validateForm()) {
            return;
        }

        try {
            const response = await post("/login", { email, password })

            const data = await response.json();

            if (!response.ok) {
              throw new Error(data.message);
            }

            if (rememberMe) {
              localStorage.setItem('rememberMeEmail', email);
            } else {
              localStorage.removeItem('rememberMeEmail');
            }


            localStorage.setItem('token', data.token);
            navigate('/dashboard');
        } catch (err) {
            if (err instanceof Error) {
                setFormError(err.message);
            } else {
                setFormError('An unexpected error occurred.');
            }
        }
    };

    return (
      <LoginContainer>
            <form onSubmit={e => handleLogin(e)}>
              <VStack spacing={7}>
                <FormControl id="email">
                    <InputGroup>
                      <InputLeftElement
                        className="InputLeft"
                        pointerEvents="none"
                        children={<BsPersonFill/>}
                      />
                      <Input placeholder='Email' type='email' value={email} onChange={e => setEmail(e.target.value)}/>
                    </InputGroup>
                </FormControl>
                <FormControl id="password">
                  <InputGroup>
                    <InputLeftElement
                        className="InputLeft"
                        pointerEvents="none"
                        children={<BsFileLockFill />}
                      />
                    <Input type="password" placeholder='Password' onChange={e => setPassword(e.target.value)}/>
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <Text color={'red'}>{formError}</Text>
                  <Text color={'red'}>{emailError}</Text>
                  <Text color={'red'}>{passwordError}</Text>
                </FormControl>
                <Flex width='100%'>
                  <Checkbox isChecked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} >
                      Remember me 
                    </Checkbox>
                    <Spacer/>
                    <ChakraLink as={ReactRouterLink} to='/forgot-password'>
                  Forgot password
                </ChakraLink>
                </Flex>
                <Button mt='4'
                  width='100%'
                  bg={'green'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  type="submit">
                  Login
                </Button>
                <ChakraLink as={ReactRouterLink} to='/register'>
                  Don't have an account yet? Create an account!
                </ChakraLink>
              </VStack>
            </form>
      </LoginContainer> 
    );
};

export default LoginPage;
