import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { post } from '../../Utils/APIHelpers';
import { IconButton,  Box, Button, Checkbox, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, CheckboxGroup, FormControl, FormErrorMessage, FormLabel, Input, Link as ChakraLink, HStack, Card, background, useColorMode, SelectField, Stack, Divider, VStack, Text } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import LoginContainer from '../Login/LoginContainer';
import './Register.css';
import { Select } from 'chakra-react-select';
import { InputActionMeta } from 'react-select';

const options = [
    {value: '2.0', label: '2.0 - Beginner'},
    {value: '2.5', label: '2.5'},
    {value: '3.0', label: '3.0'},
    {value: '3.5', label: '3.5'},
    {value: '4.0', label: '4.0'},
    {value: '4.5', label: '4.5 - Intermediate'},
    {value: '5.0', label: '5.0'},
    {value: '5.5', label: '5.5'},
    {value: '6.0', label: '6.0 - Semi-Pro'}
]

const options2 = [
    "3.0",
    "2.0"
]

interface StateOption {
    readonly value: string;
    readonly label: string;
}

const RegisterPage: React.FC = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [highestUSTARanking, setHighestUSTARanking] = useState('');
    const [preferredPlayTime, setPreferredPlayTime] = useState('');
    const [gender, setGender] = useState('');
    const [occupation, setOccupation] = useState('');
    const [company, setCompany] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [calendarLink, setCalendarLink] = useState('');
    const [playPreference, setPlayPreference] = useState('');
    const [hearAboutLadder, setHearAboutLadder] = useState<string[]>([]);
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
    const [formError, setFormError] = useState('');
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [isFormOpen, setIsFormOpen] = useState(true);
    const navigate = useNavigate();

    const validateForm = () => {
        let isValid = true;
        setFirstNameError('');
        setLastNameError('');
        setEmailError('');
        setPasswordError('');
        setPasswordConfirmationError('');
        if (!firstName) {
            setFirstNameError('First name is required.');
            isValid = false;
        }

        if (!lastName) {
            setLastNameError('Last name is required.');
            isValid = false;
        }

        if (!email) {
            setEmailError('Email is required.');
            isValid = false;
        } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setEmailError('Email is invalid.');
            isValid = false;
        }

        if (!password) {
            setPasswordError('Password is required.');
            isValid = false;
        } else if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters.');
            isValid = false;
        } else if (!/[A-Z]/.test(password)) {
            setPasswordError('Password must contain at least one uppercase letter.');
            isValid = false;
        } else if (!/[a-z]/.test(password)) {
            setPasswordError('Password must contain at least one lowercase letter.');
            isValid = false;
        } else if (!/[0-9]/.test(password)) {
            setPasswordError('Password must contain at least one number.');
            isValid = false;
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            setPasswordError('Password must contain at least one special character.');
            isValid = false;
        }

        if (!passwordConfirmation) {
            setPasswordConfirmationError('Please confirm your password.');
            isValid = false;
        }

        if (password !== passwordConfirmation) {
            setPasswordConfirmationError('Passwords do not match.');
            isValid = false;
        }
        if (!agreeTerms) {
            setFormError('You must agree to the terms and conditions.');
            isValid = false;
        }

        return isValid;
    };

    const handleSignup = async (e: React.FormEvent) => {
        e.preventDefault();
        setFormError('');

        if (!validateForm()) {
            return;
        }

        try {
            const response = await post('/register', {
                firstName,
                lastName,
                email,
                password,
                highestUSTARanking,
                preferredPlayTime,
                gender,
                occupation,
                company,
                agreeTerms,
                calendarLink,
                playPreference,
                hearAboutLadder, 
                phoneNumber
            });

            // Assuming the API response includes a token upon successful signup
            const data = await response.json();
            if (data.message) {
                throw new Error(data.message)
            }

            localStorage.setItem('token', data.token);
            //navigate('/dashboard'); // Redirect to dashboard or a welcome page
            setIsConfirmationModalOpen(true);
        } catch (err) {
            if (err instanceof Error) {
                setFormError(err.message);
            } else {
                setFormError('An unexpected error occurred.');
            }
        }
    };

    const openModal = () => {
        setIsTermsModalOpen(true);
        setIsConfirmationModalOpen(false);
    };

    const closeModal = () => {
        if (isTermsModalOpen) {
            setIsTermsModalOpen(false);
        }
        if (isConfirmationModalOpen) {
            setIsConfirmationModalOpen(false);
            navigate('/dashboard'); // Redirect to login page
        }
    };

    const handleClose = () => {
        if (isFormOpen){
        setIsFormOpen(false);
        navigate('/dashboard');
        } // Close the form
    };
    

    return (
        <LoginContainer>
            <form onSubmit={handleSignup}>
            <IconButton
                aria-label="Close"
                icon={<MdClose/>}
                onClick={handleClose} // Define a function to handle closing the form
                variant="ghost"
                position="absolute"
                top="1rem"
                right="1rem"
            />
                <HStack spacing={6}>
                    <FormControl isRequired isInvalid={!!firstNameError} mb="4">
                        <FormLabel htmlFor="firstName">First Name</FormLabel>
                        <Input id="firstName" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        <FormErrorMessage>{firstNameError}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={!!lastNameError} mb="4">
                        <FormLabel htmlFor="lastName">Last Name</FormLabel>
                        <Input id="lastName" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        <FormErrorMessage>{lastNameError}</FormErrorMessage>
                    </FormControl>
                </HStack>
                <HStack spacing={6}>
                    <FormControl isRequired isInvalid={!!passwordError} mb="4">
                        <FormLabel htmlFor="password">Password</FormLabel>
                        <Input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <FormErrorMessage>{passwordError}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={!!passwordConfirmationError} mb="4">
                    <FormLabel htmlFor="passwordConfirmation">Confirm Password</FormLabel>
                    <Input id="passwordConfirmation" type="password" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
                    <FormErrorMessage>{passwordConfirmationError}</FormErrorMessage>
                </FormControl>
                </HStack>
                <FormControl isRequired isInvalid={!!emailError} mb="4">
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <FormErrorMessage>{emailError}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired  mb="4">
                    <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
                    <Input id="phoneNumber" type="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    <FormErrorMessage>{phoneNumberError}</FormErrorMessage>
                </FormControl>
                <Stack spacing={[1,5]} direction={['column', 'row']}>
                    <FormControl isRequired mb="4">
                        <FormLabel>Highest USTA Ranking</FormLabel>
                        <Select value={{ value: highestUSTARanking, label: highestUSTARanking }}
                            onChange={(option, e) => setHighestUSTARanking(option?.value || "")}
                            placeholder='Select'
                            options={options}/>
                    </FormControl>
                    <FormControl isRequired mb="4">
                        <FormLabel>Preferred Play Time</FormLabel>
                        <Select value={{value: preferredPlayTime, label: preferredPlayTime}} 
                                onChange={(e) => setPreferredPlayTime(e?.label || "")}
                                options={[
                                    {value: "During Work Hours", label: "During Work Hours"},
                                    {value: "Outside Work Hours", label: "Outside Work Hours"},
                                    {value: "Either", label: "Either"},
                                ]}/>
                    </FormControl>
                </Stack>
                <FormControl isRequired mb="4">
                    <FormLabel>Gender</FormLabel>
                    <Select value={{value: gender, label: gender}} onChange={(e) => setGender(e?.value || "")}
                            options={[
                                {value: "Male", label: "Male"},
                                {value: "Female", label: "Female"}                                
                            ]}/>
                </FormControl>
                <FormControl mb="4">
                    <FormLabel>Occupation</FormLabel>
                    <Input value={occupation} onChange={(e) => setOccupation(e.target.value)} />
                </FormControl>
                <FormControl mb="4">
                    <FormLabel>Company</FormLabel>
                    <Input value={company} onChange={(e) => setCompany(e.target.value)} />
                </FormControl>
                <FormControl isRequired mb="4">
                    <FormLabel>Link to your personal calendar. Highly encouraged. N/A if you don't have one. </FormLabel>
                    <Input value={calendarLink} onChange={(e) => setCalendarLink(e.target.value)} />
                </FormControl>
                <FormControl mb="4">
                    <FormLabel>Do you prefer playing singles, doubles, or both?</FormLabel>
                    <Select value={{value: playPreference, label: playPreference}} onChange={(e) => setPlayPreference(e?.value || "")}
                            options={[
                                {value: "Singles", label: "Singles"},
                                {value: "Doubles", label: "Doubles"},
                                {value: "Both", label: "Both"},
                            ]}/>
                </FormControl>
                <FormControl mb="4">
                    <FormLabel>How did you hear about the ladder?</FormLabel>
                    <CheckboxGroup colorScheme="green" value={hearAboutLadder.map(value => String(value))} onChange={(values: string[]) => setHearAboutLadder(values)}>
                        <Stack spacing={[1,5]} direction={['column', 'row']}>
                            <Checkbox value="Friend">Friend</Checkbox>
                            <Checkbox value="Website">Website</Checkbox>
                            <Checkbox value="Social Media">Social Media</Checkbox>
                            <Checkbox value="Event">Event</Checkbox>
                            <Checkbox value="Other">Other</Checkbox>
                        </Stack>
                    </CheckboxGroup>
                </FormControl>
                <Divider/>
                <FormControl isRequired mb="4" mt="3">
                    <HStack spacing={5}>
                        <Checkbox colorScheme="green" isChecked={agreeTerms} onChange={(e) => setAgreeTerms(e.target.checked)}>
                            I have read the{' '}
                            <ChakraLink onClick={openModal}   color="blue">
                                information page
                            </ChakraLink>
                        </Checkbox>
                    </HStack>
                </FormControl>
                {formError && <Text className="form-error" mb={3}>{formError}</Text>}
                <Button width='100%' type="submit" colorScheme="blue" mb="4">Register</Button>
                {/* Modal component */}
                <Modal isOpen={isTermsModalOpen} onClose={closeModal} size="lg">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Information Page</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {/* Content of the modal */}
                            <Text>Directions: <a href="https://docs.google.com/document/d/1vz9SnJLdOPHNDyPwGcrb9o0cnJughMSag0KUiB0YGes/edit" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Google Docs Link</a></Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={closeModal}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

            {/* Confirmation Modal */}
            <Modal isOpen={isConfirmationModalOpen} onClose={closeModal} size="md">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Registration Successful</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Your registration was successful. A confirmation email has been sent.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={closeModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

                <div>
                    <ChakraLink as={Link} to="/login" color={'blue.400'}>Already have an account? Login</ChakraLink>
                </div>
            </form>
        </LoginContainer>
    );
};

export default RegisterPage;