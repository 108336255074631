import React from 'react';
import { Box, Heading, Text, VStack, Divider, Link } from "@chakra-ui/react";

const Courts: React.FC = () => {
    return (
        <Box maxW="800px" mx="auto" p="4">
            <Heading as="h1" size="xl" mb="4">Tennis Court Locations</Heading>
            
            <VStack spacing={4} align="start">
                <Box>
                    <Heading as="h2" size="lg" mb="2">McKinley Park Tennis Courts</Heading>
                    <Text fontSize="lg">McKinley Park offers several tennis courts for public use. The courts are well-maintained and are suitable for players of all skill levels.</Text>
                    <Text fontSize="lg">Address: 1750 N Lincoln Memorial Dr, Milwaukee, WI 53202</Text>
                    <Link href="https://maps.google.com/?q=1750+N+Lincoln+Memorial+Dr,+Milwaukee,+WI+53202" isExternal color="blue.400">View on Google Maps</Link>
                </Box>
                <Divider />
                <Box>
                    <Heading as="h2" size="lg" mb="2">Hart Park Tennis Courts</Heading>
                    <Text fontSize="lg">Hart Park features multiple tennis courts surrounded by beautiful greenery. The courts are well-maintained and are often used for recreational play as well as organized tournaments.</Text>
                    <Text fontSize="lg">Address: 7300 Chestnut St, Wauwatosa, WI 53213</Text>
                    <Link href="https://maps.google.com/?q=7300+Chestnut+St,+Wauwatosa,+WI+53213" isExternal color="blue.400">View on Google Maps</Link>
                </Box>
                <Divider />
                {/* Add more tennis courts as needed */}
            </VStack>
        </Box>
    );
};

export default Courts;