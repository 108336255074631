import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { Content } from '../Pages/Content';
import Header from '../Pages/Header';
import { Box } from '@chakra-ui/react';
import { getNameFromToken, isAuth } from '../Utils/IsAuth';

interface NormalRouteProps {
    children: ReactNode;
}

export function NormalRoute({children}: NormalRouteProps) {

    const comp = isAuth() ? 
    <Header username={getNameFromToken()} role={undefined}>
        {children}
    </Header> : 
    <Header username={undefined} role={undefined}>
        {children}
    </Header>

    return comp
}
