import { Box, Button, Flex, Table, Thead, Tbody, Tr, Th, Td, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Textarea, useDisclosure } from "@chakra-ui/react";
import Header from "../Header";
import { getAuth, postAuth } from "../../Utils/APIHelpers";
import { useEffect, useState } from "react";

type User = {
    userProfileId: number;
    email: String;
    firstName: String;
    lastName: String;
    highestUstaRanking: number;
    preferredPlayTime: String;
    gender: String;
    occupation: String;
    company: String;
    calendarLink: String;
    playPreference: "Singles" | "Doubles" | "Both";
    hearAboutLadder: String;
    agreeTerms: Boolean;
    phoneNumber: String;
}

export const AdminUsers: React.FC = () => {

    const [users, setUsers] = useState<User[]>([])

    async function fetchUsers() {
        try {
            const response = await getAuth(`/users/admin/`);
            const json = await response.json();
            console.log(json)
            setUsers(json as User[])
        } catch (error) {
            console.error('Error fetching routine details:', error);
        }
    }


    useEffect(() => {
        fetchUsers()
    }, []); // Fetch routine details when RoutineID changes


    return (
        <Box>
            <Header username={undefined} role={undefined} />
            <Table variant="simple">
                <Thead>
                    <Tr>
                    <Th>ID</Th>
                    <Th>Email</Th>
                    <Th>First Name</Th>
                    <Th>Last Name</Th>
                    <Th>Phone Number</Th>
                    <Th>Highest USTA Ranking</Th>
                    <Th>Preferred Play Time</Th>
                    <Th>Gender</Th>
                    <Th>Occupation</Th>
                    <Th>Company</Th>
                    <Th>Calendar Link</Th>
                    <Th>Play Preference</Th>
                    <Th>Heard About Ladder</Th>
                    <Th>Agree to Terms</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {users.map((user) => (
                    <Tr key={user.userProfileId}>
                        <Td>{user.userProfileId}</Td>
                        <Td>{user.email}</Td>
                        <Td>{user.firstName}</Td>
                        <Td>{user.lastName}</Td>
                        <Td>{user.phoneNumber}</Td>
                        <Td>{user.highestUstaRanking}</Td>
                        <Td>{user.preferredPlayTime}</Td>
                        <Td>{user.gender}</Td>
                        <Td>{user.occupation}</Td>
                        <Td>{user.company}</Td>
                        <Td>
                            <Link>{user.calendarLink}</Link>
                        </Td>
                        <Td>{user.playPreference}</Td>
                        <Td>{user.hearAboutLadder}</Td>
                        <Td>{user.agreeTerms ? "Yes" : "No"}</Td>
                    </Tr>
                    ))}
                </Tbody>
            </Table>

        </Box>
    );
}