import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../Utils/APIHelpers';
import { isAuth } from '../../Utils/IsAuth';
import { Box, Button, Card, Flex, FormControl, HStack, Heading, Image, Input, InputGroup, InputLeftElement, Stack, VStack } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import tennisImage from '../../Assets/tennis.jpg'
import logo from '../../Assets/placeholder-logo.png'

type LoginContainerProps = {
    children: ReactNode
}

const LoginContainer: React.FC<LoginContainerProps> = ({children}) => {

    return (
        <Flex
        bgImage={tennisImage} 
        backgroundPosition={'center'} 
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        backgroundRepeat='no-repeat'
        bgSize='cover'>
        <ColorModeSwitcher alignSelf={"baseline"}></ColorModeSwitcher>
          <Card
            rounded={'lg'}
            boxShadow={'xl'}
            p={8}
            variant={"elevated"}
            height={'fit-content'}
            width={'2xl'}
            mx={'auto'}
            opacity='95%'
            my='7'
            >
            <Stack spacing={8}>
              <HStack alignSelf={'center'}>
                <Image src={logo} boxSize={'30px'} mr={3}></Image>
                <Heading fontSize={'4xl'}>MKE Tennis</Heading>
              </HStack>
            {children}
            </Stack>
          </Card>
      </Flex>
    );
};

export default LoginContainer;