import { Avatar, Box, Card, CardBody, CardHeader, Center, Divider, Flex, Grid, HStack, Heading, Spacer, Text, VStack, useColorModeValue } from "@chakra-ui/react"
import { Component, ReactElement, ReactNode } from "react"
import { BsCaretRightFill, BsFillCaretRightFill } from "react-icons/bs"
import { MatchData } from "./Matches"


type MatchProps = {
    myMatch: MatchData
}


function displayScore(score: number[], team: number): ReactElement {
    if (Number.isNaN(score[0])) {
        return (<Flex></Flex>)
    } else {
        if (team == 0) {
            if (score[0] == 6 && score[1] < 6) {
                return (<Heading size={'sm'} alignItems={'flex-start'}>{score[0]}</Heading>)
            } else if (score[0] == 7) {
                return (<Heading size={'sm'} alignItems={'flex-start'}>{score[0]}</Heading>)
            } else {
                return (<Text size={'sm'} alignItems={'flex-start'}>{score[0]}</Text>)
            }
        } else {
            if (score[1] == 6 && score[0] < 6) {
                return (<Heading size={'sm'} alignItems={'flex-start'}>{score[1]}</Heading>)
            } else if (score[1] == 7) {
                return (<Heading size={'sm'} alignItems={'flex-start'}>{score[1]}</Heading>)
            } else {
                return (<Text size={'sm'} alignItems={'flex-start'}>{score[1]}</Text>)
            }
        }
    }
}

function parseScores(scores: string): number[][] {
    const splitScores = scores.split("|")
    const team1_scores = splitScores[0].split(",").map((value) => Number(value))
    const team2_scores = splitScores[1].split(",").map((value) => Number(value))

    const array = []
    array[0] = [team1_scores[0], team2_scores[0]]
    array[1] = [team1_scores[1], team2_scores[1]]
    array[2] = [team1_scores[2], team2_scores[2]]
    return array
}


function createAvatarAndName(player: string, elo: number): ReactNode {
    return (
        <HStack>
        <Avatar size={'xs'}/>
        <HStack>
            <Text fontWeight={'bold'}>{player}</Text>
            <Text fontSize={'small'} color={'gray.500'}>({elo})</Text>
        </HStack>
    </HStack>
    )
}

function createTeamVStack(player1: string, player1Elo: number, player2: string, player2Elo: number): ReactNode {
    if (player2 == null) {
        return createAvatarAndName(player1, player1Elo)
    } else {
        return (
            <VStack align={'start'}>
                {createAvatarAndName(player1, player1Elo)}
                {createAvatarAndName(player2, player2Elo)}
            </VStack>
        )
    }
}


const Match: React.FC<MatchProps> = ({myMatch}) => {

    const scores = parseScores(myMatch.matchRecord)

    return (
    <Box p="2" borderRadius="md" overflow={'hidden'}>
        <Card minWidth='md' variant={'elevated'} p='4' bgColor={useColorModeValue('white', 'gray.600')} width={'inherit'}>
            <Center><Text fontWeight={'bold'}>{myMatch.date}</Text></Center>
            <Flex direction={'column'} mt='3'>
                <Flex mb={'5'}>
                    {createTeamVStack(myMatch.team1Player1Name, myMatch.team1Player1NewElo, myMatch.team1Player2Name, myMatch.team1Player2Elo)}
                    <Spacer/>       
                    <Flex>
                        <HStack spacing={'8'}>
                            {scores.map((score, index) => displayScore(score, 0))}
                        </HStack>
                    </Flex>
                </Flex>
                <Divider></Divider>
                <Flex mt={'5'}>
                    {createTeamVStack(myMatch.team2Player1Name, myMatch.team2Player1NewElo, myMatch.team2Player2Name, myMatch.team2Player2Elo)}

                    {/* <HStack>
                        <BsFillCaretRightFill />
                        <Avatar size={'xs'}/>
                        <HStack>
                            <Text fontWeight={'bold'}>{myMatch.team2Player1Name}</Text>
                            <Text fontSize={'small'} color={'gray.500'}>({myMatch.team2Player1NewElo})</Text>
                        </HStack>
                    </HStack> */}
                    <Spacer/>       
                    <Flex alignSelf={'end'}>
                        <HStack spacing={'8'}>
                            {scores.map((score, index) => displayScore(score, 1))}
                        </HStack>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    </Box>
  )

}


export default Match;