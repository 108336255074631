import React from 'react';
import { Box, Heading, Text, UnorderedList, ListItem, Link as ChakraLink } from "@chakra-ui/react";

const EventsPage: React.FC = () => {
    return (
        <Box maxW="800px" mx="auto" p="4">
            <Heading as="h1" size="xl" mb="4">Tennis Events</Heading>
            <Text fontSize="xl" mb="4">
                Explore our upcoming tennis events and mark your calendars!
            </Text>

            <Box mb="6">
                <Heading as="h2" size="lg" mb="2">Upcoming Events</Heading>
                <UnorderedList>
                <ChakraLink href="https://mckinleymixed.com/">Mckinely Mixed</ChakraLink>
                    <ListItem>
                        <strong>Event:</strong> Wimbledon Championships<br />
                        <strong>Date:</strong> July 1 - July 14, 2024<br />
                        <strong>Time:</strong> 9:00 AM - 6:00 PM
                    </ListItem>
                    <ListItem>
                        <strong>Event:</strong> US Open<br />
                        <strong>Date:</strong> August 26 - September 8, 2024<br />
                        <strong>Time:</strong> 10:00 AM - 8:00 PM
                    </ListItem>
                    <ListItem>
                        <strong>Event:</strong> Australian Open<br />
                        <strong>Date:</strong> January 20 - February 2, 2025<br />
                        <strong>Time:</strong> 8:30 AM - 7:00 PM
                    </ListItem>
                </UnorderedList>
            </Box>

            <Text fontSize="lg">
                Stay tuned for more events!
            </Text>
        </Box>
    );
};

export default EventsPage;
