import { AbsoluteCenter, Box, Button, Divider, Flex, HStack, Heading, Text, VStack, Link, Center } from "@chakra-ui/react"
import tennisImage from '../Assets/tennis.jpg'
import { useNavigate } from "react-router";
import Header from "./Header";




export const Info: React.FC = () => {

    const navigate = useNavigate();

    return (
        <Box
            bgImage={tennisImage}
            backgroundPosition={'center'}
            height="100vh"
            width='100%'
            backgroundRepeat='no-repeat'
            bgSize='cover'
            position="relative"
        >
            <Header username={undefined} role={undefined}/>
            <Center>
                <Box
                    bg="rgba(0, 0, 0, 0.5)"
                    mt={7}
                    padding={8}
                    borderRadius={8}
                    textAlign="center"
                    color="white"
                >
                    <VStack spacing={10}>
                    <Link href="https://docs.google.com/document/d/1vz9SnJLdOPHNDyPwGcrb9o0cnJughMSag0KUiB0YGes/edit" isExternal>
                        <Text fontSize="xl">Click here to learn about MKE tennis ladder</Text>
                    </Link>
                        <Text fontSize="6xl" >Exciting Updates Ahead</Text>
                        <Heading fontSize="7xl" >Welcome to MKE Tennis</Heading>
                        <Text fontSize="xl" opacity="0.8">Join our exciting tennis community and experience the thrill of competitive play.</Text>
                        <HStack spacing={8}>
                            <Button colorScheme='green' size="lg" onClick={() => navigate('/login')} >Login</Button>
                            <Button colorScheme="green" size="lg" onClick={() => navigate('/register')}>Register</Button>
                        </HStack>
                    </VStack>
                </Box>
            </Center>
        </Box>
    );
}


