import React from 'react';
import { Box, Heading, Text, UnorderedList, ListItem, Link as ChakraLink } from "@chakra-ui/react";

const TournamentsPage: React.FC = () => {
    return (
        <Box maxW="800px" mx="auto" p="4">
            <Heading as="h1" size="xl" mb="4">Tennis Tournaments</Heading>
            <Text fontSize="xl" mb="4">
                Welcome to our Tennis Tournaments page! Here you'll find information about upcoming tournaments, past tournaments, and more.
            </Text>

            <Box mb="6">
                <Heading as="h2" size="lg" mb="2">Upcoming Tournaments</Heading>
                <UnorderedList>
                    <ListItem>Drew Lied Open</ListItem>
                    <ListItem>Billy Bertha's big fast 4</ListItem>
                    <ListItem>Mark Sansait invitational</ListItem>
                    <ChakraLink href="https://mckinleymixed.com/">Mckinely Mixed</ChakraLink>
                </UnorderedList>
            </Box>

            <Text fontSize="lg">
                Stay tuned for more updates!
            </Text>
        </Box>
    );
};

export default TournamentsPage;
