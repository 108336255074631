import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { isAuth, getEmailFromToken, getNameFromToken } from './IsAuth';
import { Content } from '../Pages/Content';
import Header from '../Pages/Header';

interface ProtectedRouteProps {
    children: ReactNode;
}

export function ProtectedRoute({children}: ProtectedRouteProps) {

    const comp = isAuth() ? 
    <Header username={getNameFromToken()} role={undefined}>
        {children}
    </Header> : <Navigate to="/login" />

    return comp
}
