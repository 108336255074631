// App.tsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './Pages/Login/Login';
import RegisterPage from './Pages/Signup/Register';
import ForgotPasswordPage from './Pages/ForgotPassword/ForgotPassword';
import ResetPasswordPage from './Pages/ResetPassword/ResetPassword';
import DashboardPage from './Pages/Dashboard/Dashboart';
import { ProtectedRoute } from './Utils/ProtectedRoute';
import { NormalRoute } from './Pages/NormalRoute';
import { Info } from './Pages/Info';
import { AdminUsers } from './Pages/Admin/AdminUsers';
import Tournaments from './Pages/Tournaments/Tournaments';
import PickUpTennis from './Pages/PickUpTennis/PickUpTennis';
import Events from './Pages/Events/Events';
import Courts from './Pages/Courts/Courts';
import LadderPage from './Pages/Ladder/LadderPage';
const App: React.FC = () => {
    return (
        <Router>
                <Routes>
                    <Route path='/' element={<Info/>}/>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path='/admin/users' element={<AdminUsers/>}/>
                    <Route path="/dashboard" element={<ProtectedRoute children={<DashboardPage/>}/>}/>
                    <Route path='/ladder' element={<ProtectedRoute><LadderPage/></ProtectedRoute>}/>
                    <Route path="/tournaments" element={<NormalRoute children={<Tournaments />} />}/>
                    <Route path="/PickUpTennis" element={<NormalRoute children={<PickUpTennis />}/>} />
                    <Route path="/Events" element={<NormalRoute children={<Events />}/>} />
                    <Route path="/Courts" element={<NormalRoute children={<Courts />}/>} />
                    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                    <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
        </Router>
    );
};

export default App;
