import { Box, Button, Card, CardBody, CardHeader, Center, Flex, HStack, Heading, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, VStack } from "@chakra-ui/react"
import { getAuth } from "../../../Utils/APIHelpers"
import { ReactNode, useEffect, useState } from "react"
import { BsFillTrophyFill } from "react-icons/bs"
import { MdOutlineFileCopy } from "react-icons/md";

const DEFAULT_PATH = "http://localhost:5000/api"


type EloRankingProps = {
    isDetailed: boolean
}

interface UserElo {
    firstName: string,
    lastName: string,
    elo: number,
    email: string
}

const EloRankingComponent: React.FC<EloRankingProps> = ({ isDetailed }: EloRankingProps) => {

    const [eloList, setEloList] = useState<UserElo[]>([])

    async function fetchElo() {
        try {
            const response = await getAuth("/users/elo")
            const data = await response.json();
            setEloList(data as UserElo[])
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchElo()
    }, [])

    async function handleCopyClick(text: string) {
        try {
            await navigator.clipboard.writeText(text);
          } catch (error) {
            console.error('Error copying text:', error);
          }
    }

    function handleNameClick(user: UserElo): ReactNode {
        return (
            <Popover placement='top-start'>
                <PopoverTrigger>
                    <Text cursor={'pointer'} fontWeight={"bold"}>{user.firstName} {user.lastName}</Text>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverHeader fontWeight='semibold'>Contact Info</PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                        <HStack>
                            <Text>{user.email}</Text>
                            <Tooltip label="Copy">
                                <IconButton aria-label="Copy" size={'xs'} icon={<MdOutlineFileCopy/>} onClick={(e) => handleCopyClick(user.email)}></IconButton>
                            </Tooltip>
                        </HStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        )
    }

    return (
            <Card width={'inherit'} variant={'elevated'} overflow={'scroll'}>
                <Center pt='2'>
                    <VStack>
                    <HStack>
                        <BsFillTrophyFill/>
                        <Heading size={'md'} ml={3}>Ladder Rankings</Heading>
                    </HStack>
                    <Text fontStyle={"italic"}>
                        1) Click on a name for contact info 

                    </Text>
                    <Text fontStyle={"italic"}>
                        2) Schedule, play, ONE person report score

                    </Text>
                    <Text fontStyle={"italic"}>
                        3) Careful, we do not have verification as of now

                    </Text>
                    </VStack>
                </Center>
            <CardBody>
                <Card variant={'outline'}>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Rank</Th>
                            <Th>Name</Th>
                            <Th>Elo</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {eloList.map((user, index) => 
                            <Tr key={index}>
                                <Td>{index+1}</Td>
                                <Td>{handleNameClick(user)}</Td>
                                <Td>{user.elo}</Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
                </Card>
            </CardBody>
        </Card>)
}

export default EloRankingComponent;