// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 65px - 60px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.forgot-password-form {
    background-color: #333;
    color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.forgot-password-actions {
    display: flex;
    justify-content: start;
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/ForgotPassword/ForgotPassword.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iCAAiC;IACjC,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".forgot-password-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: calc(100vh - 65px - 60px);\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n}\n\n.forgot-password-form {\n    background-color: #333;\n    color: white;\n    padding: 20px;\n    border-radius: 5px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    width: 300px;\n}\n\n.forgot-password-actions {\n    display: flex;\n    justify-content: start;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
