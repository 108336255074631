import React from 'react';
import image from '../../Assets/Screenshot 2024-04-18 003910.png'

const PickUpTennis: React.FC = () => {
    return (
        <div>
            <h1>Pick Up Tennis</h1>
            <p>Organized by your ambassadors!</p>
        </div>
    );
};

export default PickUpTennis;