import React, { useEffect, useState } from 'react';
import { Button, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Center, Box, Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { getEmailFromToken, getNameFromToken } from '../../Utils/IsAuth';
import Matches from '../Dashboard/Components/Matches/Matches';
import EloRankingComponent from '../Dashboard/Components/EloRanking';

const LadderPage: React.FC = () => {
    const email: string = getEmailFromToken();


    return (
        <Flex flexDirection={'column'}>
            <Flex flexDirection="row" height={'86vh'} ml={'7'}>
                <Flex width={'47vw'}>
                    <EloRankingComponent isDetailed={true} />
                </Flex>
                <Flex ml={3} width={'xl'}>
                    <Matches endpoint='/matches/' title='All Matches' withReportScores={false}></Matches>
                </Flex>
            </Flex>
        </Flex>
    );
};

// export default DashboardPage;

export default LadderPage;