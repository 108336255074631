import React, { Component, ReactNode } from 'react';
import {
  IconButton,
  Box,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  FlexProps,
  Spacer,
  Heading,
  Link,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Avatar,
  Button,
  StackDivider,
  Divider,
  Center,
  Portal
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { BsFillDiagram3Fill, BsFillCalendarDateFill, BsPeopleFill, BsFillPinMapFill, BsFillPersonFill } from "react-icons/bs";
import { ReactText } from 'react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import logo from '../Assets/placeholder-logo.png'
import {BellIcon, ChevronDownIcon} from '@chakra-ui/icons'

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactText;
    link: string;
  }
  const HeaderItem = ({ icon, children, link, ...rest }: NavItemProps) => {
    return (
      <Link href={link} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'cyan.400',
            color: 'white',
          }}
          {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
    );
  };
  
  interface MobileProps extends FlexProps {
    username: string | undefined;
    role: string | undefined;
  }


 const UserProfileComponent: React.FC<string> = (name: string) => {
  return (
    <HStack spacing={{ base: '0', md: '6' }}>
    <IconButton
      size="lg"
      variant="ghost"
      aria-label="open menu"
      icon={<BellIcon />}
    />
    <Flex alignItems={'center'}>
      <Menu>
        <MenuButton
          py={2}
          transition="all 0.3s"
          _focus={{ boxShadow: 'none' }}>
          <HStack>
            <Avatar size={'sm'}/>
            <VStack
              display={{ base: 'none', md: 'flex' }}
              alignItems="flex-start"
              spacing="1px"
              ml="2">
              <Text fontSize="sm">{name}</Text>
              <Text fontSize="xs" color="gray.600">
                User
              </Text>
            </VStack>
            <Box display={{ base: 'none', md: 'flex' }}>
              <ChevronDownIcon />
            </Box>
          </HStack>
        </MenuButton>
        <Portal>
          <MenuList
            borderColor={useColorModeValue('gray.300', 'gray.550')}>
            <MenuItem>Profile</MenuItem>
            <MenuItem>Settings</MenuItem>
            <MenuDivider/>
            <MenuItem onClick={() => {
              localStorage.removeItem("token")
              window.location.reload();
            }}>Sign out</MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  </HStack>
  )
 }


  const Header = ({ username, role, children }: MobileProps) => {

    return (
      <Box>
        <Flex
          px={{ base: 4, md: 4 }}
          py={'2'}
          height={'inherit'}
          alignItems="center"
          bg={useColorModeValue('white', 'gray.900')}
          opacity='75%'
          borderBottomWidth="1px"
          borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
          justifyContent={{ base: 'space-between', md: 'flex-end' }}>
          <HStack divider={<StackDivider></StackDivider>}>
            <Link _hover={{ textDecoration: "none" }} href='/dashboard'>
              <Center pr={5}>
                  <Image src={logo} boxSize={'30px'} mr={3}></Image>
                  <Heading fontSize={'xx-large'} fontStyle={'italic'}>
                    MKE Tennis
                  </Heading>
              </Center>
            </Link>
            <HeaderItem icon={BsFillDiagram3Fill} link="/tournaments">
              Tournaments
            </HeaderItem>
            <HeaderItem icon={BsFillCalendarDateFill} link="/events">
              Events
            </HeaderItem>
            <HeaderItem icon={BsPeopleFill} link="/pickuptennis">
              Pick Up Tennis
            </HeaderItem>
            <HeaderItem icon={BsFillPinMapFill} link="/courts">
              Courts
            </HeaderItem>
            <HeaderItem icon={BsFillDiagram3Fill} link="/ladder">
              Ladder
            </HeaderItem>
          </HStack>
          <Spacer/>
          <ColorModeSwitcher justifySelf={'start'}></ColorModeSwitcher>
          <IconButton
            display={{ base: 'flex', md: 'none' }}
            variant="outline"
            aria-label="open menu"
            icon={<ChevronDownIcon />}
          />
          <Text
            display={{ base: 'flex', md: 'none' }}
            fontSize="2xl"
            fontFamily="monospace"
            fontWeight="bold">
            Logo
          </Text>
          {username ? UserProfileComponent(username) : <HeaderItem icon={BsFillPersonFill} link='/login'>Login</HeaderItem>}
        </Flex>
        {children ? (<Box ml={{ base: 0, md: 0 }} p="3">
          {children}
        </Box>) : <Flex/>}
      </Box>
    );
  };

  export default Header;